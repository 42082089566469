import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "../utilities/axios";
const initialState = {
  questions: [],
  fetchStatus: "idle",
  deleteQuestionStatus: "idle",
  status: "idle",
  updateStatus: "idle",
  updatenextQuestionStatus: "idle",
  insertQuestionStatus: "idle",
};
export const createQuestion = createAsyncThunk(
  "question/createQuestion",
  async (question) => {
    let data;

    try {
      if (!question?.assign) {
        delete question?.assign;
      }
      const response = await axios.post(
        `/websites/${question.websiteID}/questions`,
        question.type === "message"
          ? {
              content: question.values,
              order: question.order,
              label: question.label,
              type: question.type,
              translations: question?.translations,
              notify: question?.notify,
              draft: question?.draft,
              displayMeta: question?.displayMeta,
            }
          : question.type === "input"
          ? {
              content: question.values,
              order: question.order,
              label: question.label,
              notify: question?.notify,
              translations: question?.translations,
              draft: question?.draft,
              displayMeta: question?.displayMeta,
            }
          : {
              content: question.values,
              order: question.order,
              type: question.type,
              notify: question?.notify,
              assign: question?.assign && question?.assign,
              choices: question.choices,
              label: question.label,
              translations: question?.translations,
              draft: question?.draft,
              displayMeta: question?.displayMeta,
            }
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const insertQuestion = createAsyncThunk(
  "question/insertQuestion",
  async (question, thunkAPI) => {
    let data;

    try {
      if (!question?.assign) {
        delete question?.assign;
      }
      const response = await axios.post(
        `/websites/${question.websiteID}/questions/insert`,
        question.type === "message"
          ? {
              question: {
                content: question.values,
                label: question.label,
                type: question.type,
                translations: question?.translations,
                notify: question?.notify,
                draft: question?.draft,
                displayMeta: question?.displayMeta,
              },
              next: question?.next,
              prev: question?.prev,
            }
          : question.type === "input"
          ? {
              question: {
                content: question.values,
                label: question.label,
                notify: question?.notify,
                translations: question?.translations,
                draft: question?.draft,
                displayMeta: question?.displayMeta,
              },
              next: question?.next,
              prev: question?.prev,
            }
          : {
              question: {
                content: question.values,
                type: question.type,
                notify: question?.notify,
                assign: question?.assign && question?.assign,
                choices: question.choices,
                label: question.label,
                translations: question?.translations,
                draft: question?.draft,
                displayMeta: question?.displayMeta,
              },
              next: question?.next,
              prev: question?.prev,
            }
      );
      data = await response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchQuestions(question.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);

export const addChoicesToQuestion = createAsyncThunk(
  "question/addChoicesToQuestion",
  async (body) => {
    let data;
    try {
      const response = await axios.get(
        `/${body.websiteID}/questions/${body.id}/add-choice`,
        body.choices
      );
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const fetchQuestions = createAsyncThunk(
  "question/fetchQuestions",
  async (websiteID) => {
    let data;
    try {
      const response = await axios.get(`/websites/${websiteID}/questions`);
      data = await response.data.data;
      if (response.status === 200) {
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const deleteQuestion = createAsyncThunk(
  "question/deleteQuestion",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.delete(
        `/websites/${body.websiteID}/questions/${body.id}`
      );
      data = await response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchQuestions(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateQuestion = createAsyncThunk(
  "question/updateQuestion",
  async (body, thunkAPI) => {
    let data;
    try {
      if (!body?.assign) {
        delete body?.assign;
      }

      const response = await axios.put(
        `/websites/${body.websiteID}/questions/${body.id}`,
        body.type === "message"
          ? {
              choices:
                body?.choices && body?.choices !== ""
                  ? body?.choices
                  : undefined,
              content: body.content,
              order: body.order,
              label: body.label,
              type: body.type,
              next: body?.next,
              translations: body?.translations,
              notify: body?.notify,
              draft: body?.draft,
              displayMeta: body?.displayMeta,
            }
          : body.type === "input"
          ? {
              content: body.content,
              order: body.order,
              label: body.label,
              type: body.type,
              notify: body?.notify,
              draft: body?.draft,
              next: body?.next,
              choices:
                body?.choices && body?.choices !== ""
                  ? body?.choices
                  : undefined,
              translations: body?.translations,
              displayMeta: body?.displayMeta,
            }
          : {
              content: body.content,
              order: body.order,
              type: body.type,
              assign: body?.assign && body?.assign,
              notify: body?.notify,
              draft: body?.draft,
              choices: body.choices,
              next: body?.next,
              label: body.label,
              translations: body?.translations,
              displayMeta: body?.displayMeta,
            }
      );
      data = await response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchQuestions(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
export const updateNextQuestion = createAsyncThunk(
  "question/updateNextQuestion",
  async (body, thunkAPI) => {
    let data;
    try {
      const response = await axios.put(
        `websites/${body.websiteID}/questions/${body.id}/add/next-question`,
        body.type === "message"
          ? {
              choices:
                body?.choices && body?.choices !== ""
                  ? body?.choices
                  : undefined,
              content: body.content,
              order: body.order,
              label: body.label,
              type: body.type,
              next: body?.next,
              translations: body?.translations,
              notify: body?.notify,
              draft: body?.draft,
              displayMeta: body?.displayMeta,
            }
          : body.type === "input"
          ? {
              content: body.content,
              choices:
                body?.choices && body?.choices !== ""
                  ? body?.choices
                  : undefined,
              order: body.order,
              label: body.label,
              notify: body?.notify,
              translations: body?.translations,
              draft: body?.draft,
              displayMeta: body?.displayMeta,
            }
          : {
              content: body.content,
              order: body.order,
              type: body.type,
              choices: body.choices,
              label: body.label,
              notify: body?.notify,
              translations: body?.translations,
              draft: body?.draft,
              displayMeta: body?.displayMeta,
            }
      );
      data = await response.data.data;
      if (response.status === 200) {
        thunkAPI.dispatch(fetchQuestions(body.websiteID));
        return data;
      }
      throw new Error(response.statusText);
    } catch (err) {
      console.log(err);
      return Promise.reject(err.message ? err.message : data?.message);
    }
  }
);
const slice = createSlice({
  name: "question",
  initialState,
  reducers: {},
  extraReducers: {
    [createQuestion.pending]: (state) => {
      state.status = "loading";
    },
    [createQuestion.fulfilled]: (state, action) => {
      state.status = "succeeded";
      state.questions.push(action.payload);
    },
    [createQuestion.rejected]: (state, action) => {
      state.status = "failed";
      state.error = action.payload;
    },
    [fetchQuestions.pending]: (state) => {
      state.fetchStatus = "loading";
    },
    [fetchQuestions.fulfilled]: (state, action) => {
      state.fetchStatus = "succeeded";
      state.questions = action.payload;
    },
    [fetchQuestions.rejected]: (state, action) => {
      state.fetchStatus = "failed";
      state.error = action.payload;
    },
    [deleteQuestion.pending]: (state) => {
      state.deleteQuestionStatus = "loading";
    },
    [deleteQuestion.fulfilled]: (state) => {
      state.deleteQuestionStatus = "succeeded";
    },
    [deleteQuestion.rejected]: (state, action) => {
      state.deleteQuestionStatus = "failed";
      state.error = action.payload;
    },
    [updateQuestion.pending]: (state) => {
      state.updateStatus = "loading";
    },
    [updateQuestion.fulfilled]: (state) => {
      state.updateStatus = "succeeded";
    },
    [updateQuestion.rejected]: (state, action) => {
      state.updateStatus = "failed";
      state.error = action.payload;
    },
    [updateNextQuestion.pending]: (state) => {
      state.updatenextQuestionStatus = "loading";
    },
    [updateNextQuestion.fulfilled]: (state) => {
      state.updatenextQuestionStatus = "succeeded";
    },
    [updateNextQuestion.rejected]: (state) => {
      state.updatenextQuestionStatus = "failed";
    },
    [insertQuestion.pending]: (state) => {
      state.insertQuestionStatus = "loading";
    },
    [insertQuestion.fulfilled]: (state) => {
      state.insertQuestionStatus = "succeeded";
    },
    [insertQuestion.rejected]: (state) => {
      state.insertQuestionStatus = "failed";
    },
  },
});

export const reducer = slice.reducer;
export default slice;
